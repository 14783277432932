<template
  ><div>
    <section class="wrapper_switch-plans-type">
      <p
        :class="
          getPlanTypeSelect == 'month' ? 'font-bold text-color-secondary' : ''
        "
        @click="setPlanType('month')"
      >
        Mensual
      </p>
      <p>|</p>
      <p
        :class="
          getPlanTypeSelect == 'yearly' ? 'font-bold text-color-secondary' : ''
        "
        @click="setPlanType('yearly')"
      >
        Anual
      </p>
    </section>

    <TransitionFadeComponent>
      <h4
        class="h4 font-bold text-color-tertiary text-center mt-3"
        v-if="showMessage && getPlanTypeSelect === 'yearly'"
      >
        Ahorras un 10%
        <span class="font-semi-bold">con el pago anual</span>
      </h4>
    </TransitionFadeComponent>
  </div>
</template>

<script>
import TransitionFadeComponent from "@/components/Transitions/Fade";

export default {
  name: "SwitchPlanComponent",
  data: () => ({}),
  computed: {
    getPlanTypeSelect() {
      return this.$store.getters.getPlanTypeSelect;
    }
  },
  methods: {
    setPlanType(type) {
      this.$store.dispatch("setPlanTypeSelect", type);
    }
  },
  components: {
    TransitionFadeComponent
  },
  props: {
    showMessage: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper_switch-plans-type {
  display: flex;
  justify-content: space-between;
  max-width: 250px;
  padding: 1rem 2rem;
  border: solid 1px;
  border-radius: 5px;
  height: 55px;
  margin: auto;

  p {
    cursor: pointer !important;
  }
}
</style>
