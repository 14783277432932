import { render, staticRenderFns } from "./BasicPlan.vue?vue&type=template&id=5aed7ace&scoped=true"
import script from "./BasicPlan.vue?vue&type=script&lang=js"
export * from "./BasicPlan.vue?vue&type=script&lang=js"
import style0 from "./BasicPlan.vue?vue&type=style&index=0&id=5aed7ace&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aed7ace",
  null
  
)

export default component.exports