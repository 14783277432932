import { render, staticRenderFns } from "./EmprendedorPlan.vue?vue&type=template&id=0f6361c8&scoped=true"
import script from "./EmprendedorPlan.vue?vue&type=script&lang=js"
export * from "./EmprendedorPlan.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f6361c8",
  null
  
)

export default component.exports