<template>
  <div class="card card-plan card-basic-plan py-4" v-if="getPlanCurrent">
    <div
      class="card-header-plan d-flex justify-content-center align-items-center"
    >
      <div class="card-header-plan-title text-color-primary text-center">
        <h2 class="h2 font-bold">Emprendedor</h2>
        <!-- <h6 class="h6">Ideal para PYMES</h6> -->
      </div>
    </div>
    <div class="card-body-plan">
      <div class="plan-price d-flex flex-column align-items-center my-4">
        <div class="plan-price-info">
          <h1 class="h1 font-black text-color-primary d-inline-block">
            $
            <TopDecimalAmount
              :amount="
                firma_electronica || (modal && isFirmaSelected)
                  ? getPlanCurrent.price + getPlanCurrent.firma
                  : getPlanCurrent.price
              "
            />
          </h1>
          <span>+ Iva Anual</span>
          <span v-if="getPlanCurrent.type !== 'yearly'">/ mes</span>
        </div>
      </div>
      <div class="plan-content lines">
        <h6 class="font-semi-bold my-4">Características</h6>
        <p
          style="color: #3fdbbf !important; font-size: 16px !important;font-weight: 600;"
        >
          Factura hasta 5 documentos
          <MoreInfoComponent
            class="pl-2"
            content="Factura documentos mensuales"
          />
        </p>
        <p>Cotizaciones Ilimitadas</p>
        <p>Configuración de imagen corporativa</p>
        <p>Reportería</p>
        <p style="text-decoration: line-through;font-style: italic;">
          Manejo de inventario
        </p>
        <p style="text-decoration: line-through;font-style: italic;">
          Integración E-commerce
        </p>
      </div>
      <div
        class="text-center"
        v-if="getPlanCurrent.type === 'yearly' && !modal"
      >
        <p><b>Agregar Firma electrónica</b></p>
        <el-switch
          v-model="firma_electronica"
          active-color="#3fdbbf"
          inactive-color="#989aae"
        >
        </el-switch>
      </div>
      <div class="plan-button" v-if="showButton">
        <PlanCardButtonComponent
          @onPlanSelect="handlePlanSelect"
          :planKey="planSlug"
          :firmaElectronica="firma_electronica"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlanCardButtonComponent from "./PlanCardButton";
import TopDecimalAmount from "@/components/General/TopDecimalAmount";
import MoreInfoComponent from "../../components/MoreInfo";

export default {
  name: "EmprendedorPlanComponent",
  data() {
    return {
      firma_electronica: false
    };
  },
  methods: {
    handlePlanSelect() {
      this.$emit("planSelected");
    }
  },
  computed: {
    getPlanCurrent() {
      return this.$store.getters.getPlans.find(p => p.slug === this.planSlug);
    },
    getPlanTypeSelect() {
      return this.$store.getters.getPlanTypeSelect;
    },
    planSlug() {
      return `emprendedor-${this.getPlanTypeSelect}`;
    },
    isFirmaSelected() {
      return this.$store.getters.getFirma;
    }
  },
  components: {
    PlanCardButtonComponent,
    TopDecimalAmount,
    MoreInfoComponent
  },
  props: {
    showButton: {
      default: false,
      type: Boolean
    },
    modal: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped></style>
