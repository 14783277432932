<template>
  <section class="pt-5">
    <b-container fluid class="pb-4 px-lg-4">
      <b-row>
        <b-col>
          <h3 class="h3 font-bold mb-4 text-center">
            Empieza a mejorar tu negocio
            <img :src="icoBriefcase" alt="ico briefcase" class="px-2" />
            <img :src="icoRocket" alt="ico rocket" class="ml-2" />
          </h3>
          <p class="text-center mb-4">
            Elige tu plan ideal que se ajuste a tu negocio para que puedas
            ahorrar tiempo y te <br />
            puedas enfocar en seguir vendiendo tu producto o servicio
          </p>
          <SwitchPlanComponent showMessage class="mb-4" />

          <section class="content-plans mt-5">
            <b-row>
              <b-col
                xs="12"
                sm="12"
                md="3"
                v-if="getPlanTypeSelect == 'yearly'"
              >
                <EmprendedorPlanComponent
                  showButton
                  @planSelected="handlePlanSelected"
                />
              </b-col>
              <b-col xs="12" sm="12" md="3">
                <StarterPlanComponent
                  showButton
                  @planSelected="handlePlanSelected"
                />
              </b-col>
              <b-col xs="12" sm="12" md="3">
                <BasicPlanComponent
                  showButton
                  @planSelected="handlePlanSelected"
                />
              </b-col>
              <b-col xs="12" sm="12" md="3">
                <ProfessionalPlanComponent
                  showButton
                  @planSelected="handlePlanSelected"
                />
              </b-col>
              <b-col xs="12" sm="12" md="3" v-if="getPlanTypeSelect == 'month'">
                <BusinessPlanComponent
                  showButton
                  @planSelected="handlePlanSelected"
                />
              </b-col>
            </b-row>
          </section>
        </b-col>
      </b-row>

      <div class="" v-if="false">
        <b-row>
          <b-col xs="12" sm="12" md="12">
            <h3 class="h3 font-bold my-4 text-center">
              Otros Servicios
            </h3>
          </b-col>
        </b-row>

        <section class="wrapper__plan-extras p-4 mt-4">
          <b-row>
            <b-col xs="12" sm="4" md="4">
              <SignaturePlan showButton @planSelected="handlePlanSelected" />
            </b-col>
          </b-row>
        </section>
      </div>
    </b-container>

    <el-dialog
      :visible.sync="dialogPlan"
      :fullscreen="true"
      class="plan-dialog"
      :before-close="handleClose"
    >
      <slot name="title">
        <h2 class="h2 pl-4"><LogoComponent /></h2>
      </slot>
      <b-container fluid class="pb-4 px-lg-4 mt-4">
        <b-row>
          <b-col xs="12">
            <section class="content-pay-process">
              <div class="pay-process-plan-selected mb-4">
                <StarterPlanComponent
                  v-if="getPlanCurrent.name === 'Starter'"
                  :modal="true"
                />
                <BasicPlanComponent
                  v-if="getPlanCurrent.name === 'Basico'"
                  :modal="true"
                />

                <ProfessionalPlanComponent
                  v-if="getPlanCurrent.name === 'Personal'"
                  :modal="true"
                />
                <BusinessPlanComponent
                  v-if="getPlanCurrent.name === 'Empresa'"
                  :modal="true"
                />

                <SignaturePlan
                  v-if="getPlanCurrent.name === 'Firma Electrónica'"
                  :modal="true"
                />

                <EmprendedorPlanComponent
                  v-if="getPlanCurrent.name === 'Emprendedor'"
                  :modal="true"
                />
              </div>
              <div class="pay-process-steps">
                <PlanPayProcessComponent v-if="dialogPlan" :modal="true" />
              </div>
            </section>
          </b-col>
        </b-row>
      </b-container>
    </el-dialog>
  </section>
</template>

<script>
import SwitchPlanComponent from "../../components/SwitchPlan";
import BasicPlanComponent from "../../components/BasicPlan";
import StarterPlanComponent from "../../components/StarterPlan";
import ProfessionalPlanComponent from "../../components/ProfessionalPlan";
import BusinessPlanComponent from "../../components/BusinessPlan";
import EmprendedorPlanComponent from "../../components/EmprendedorPlan";
import PlanPayProcessComponent from "../../components/PayphoneProccess";
import SignaturePlan from "../../components/SignaturePlan";
import LogoComponent from "@/components/Logo/Logo";
import { icoRocket, icoBriefcase } from "@/services/resources";

export default {
  name: "PlansMainPage",
  data: () => ({
    icoRocket,
    icoBriefcase,
    dialogPlan: false
  }),
  computed: {
    getPlanCurrent() {
      return this.$store.getters.getPlanCurrent;
    },
    getPlanTypeSelect() {
      return this.$store.getters.getPlanTypeSelect;
    },
    getFirma() {
      return this.$store.getters.getFirma;
    }
  },
  methods: {
    handlePlanSelected() {
      this.dialogPlan = true;
    },
    handleClose() {
      this.dialogPlan = false;
    }
  },
  components: {
    SwitchPlanComponent,
    BasicPlanComponent,
    BusinessPlanComponent,
    ProfessionalPlanComponent,
    LogoComponent,
    PlanPayProcessComponent,
    SignaturePlan,
    StarterPlanComponent,
    EmprendedorPlanComponent
  }
};
</script>

<style lang="scss" scoped>
@import "./Main.scss";
</style>
