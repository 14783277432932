<template>
  <span class="amount-format" :class="[{ 'pr-3': amountDecimal }, formatClass]">
    <span class="amount-integer">{{ amountInt }}</span>
    <span class="amount-decimal-top pr-3">{{ amountDecimal }}</span>
  </span>
</template>

<script>
export default {
  name: "TopDecimalAmount",
  computed: {
    amountInt() {
      return parseInt(this.amount);
    },
    amountDecimal() {
      const getDecimal = this.amount.toFixed(2).slice(-2);
      return getDecimal === "00" ? "" : getDecimal;
    }
  },
  props: {
    amount: {
      default: 0.0
    },
    formatClass: {
      default: "f-h1"
    }
  }
};
</script>

<style lang="scss" scoped>
.amount-format {
  position: relative;
  .amount-decimal-top {
    position: absolute;
    min-width: max-content;
    top: 0;
  }
  &.f-h1 {
    .amount-decimal-top {
      font-size: 25px !important;
      margin-top: 8px;
    }
  }
  &.f-p {
    .amount-decimal-top {
      font-size: 10px;
    }
  }
  &.f-h3 {
    .amount-decimal-top {
      font-size: 14px;
    }
  }
  &.f-h4 {
    .amount-decimal-top {
      font-size: 10px;
    }
  }
}
</style>
