<template>
  <p v-if="type === 'yearly'" class="mt-4">
    Cobro anual $<TopDecimalAmount :amount="subTotal" />
  </p>
</template>

<script>
import TopDecimalAmount from "@/components/General/TopDecimalAmount";

export default {
  name: "PlanTypeInfo",
  data() {
    return {
      planType: this.type
    };
  },
  watch: {
    type(v) {
      this.planType = v;
    }
  },
  props: {
    type: {
      default: null
    },
    subTotal: {
      default: 0
    }
  },
  components: {
    TopDecimalAmount
  }
};
</script>

<style lang="scss" scoped></style>
