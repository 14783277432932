<template>
  <div class="card card-plan card-basic-plan py-4" v-if="getPlanCurrent">
    <div
      class="card-header-plan d-flex justify-content-center align-items-center"
    >
      <div class="card-header-plan-title text-color-primary text-center">
        <h2 class="h2 font-bold">Premium</h2>
        <!-- <h6 class="h6">Ideal para PYMES</h6> -->
      </div>
    </div>
    <div class="card-body-plan">
      <div class="plan-price d-flex flex-column align-items-center my-4">
        <div class="plan-price-info">
          <h1 class="h1 font-black text-color-primary d-inline-block">
            $
            <TopDecimalAmount :amount="getPlanCurrent.price" />
          </h1>
          <span>/ mes</span>
        </div>
        <PlanTypeInfo
          :type="getPlanCurrent.type"
          :subTotal="getPlanCurrent.subTotal"
        />
      </div>
      <div class="plan-content lines">
        <h6 class="font-semi-bold my-4">Características</h6>
        <p style="font-size: 16px !important;font-weight: 600;">
          Factura hasta 1000 documentos
          <MoreInfoComponent
            class="pl-2"
            content="Factura documentos mensuales"
          />
        </p>
        <p>Cotizaciones Ilimitadas</p>
        <p>Botón de pagos</p>
        <p>Configuración de imagen corporativa</p>
        <p>Manejo de inventario</p>
        <p>Integración E-commerce</p>
        <p>API Desarrolladores</p>
      </div>
      <div class="plan-button" v-if="showButton">
        <PlanCardButtonComponent
          @onPlanSelect="handlePlanSelect"
          :planKey="planSlug"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlanCardButtonComponent from "./PlanCardButton";
import MoreInfoComponent from "../../components/MoreInfo";
import TopDecimalAmount from "@/components/General/TopDecimalAmount";
import PlanTypeInfo from "./PlanTypeInfo";

export default {
  name: "BusinessPlanComponent",
  data() {
    return {};
  },
  methods: {
    handlePlanSelect() {
      this.$emit("planSelected");
    }
  },
  computed: {
    getPlanCurrent() {
      return this.$store.getters.getPlans.find(p => p.slug === this.planSlug);
    },
    getPlanTypeSelect() {
      return this.$store.getters.getPlanTypeSelect;
    },
    planSlug() {
      return `business-${this.getPlanTypeSelect}`;
    }
  },
  components: {
    PlanCardButtonComponent,
    MoreInfoComponent,
    TopDecimalAmount,
    PlanTypeInfo
  },
  props: {
    showButton: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped></style>
