<template>
  <section class="plan-pay-process h-100">
    <el-steps :active="active" finish-status="success">
      <el-step title=""></el-step>
      <el-step title=""></el-step>
      <el-step title=""></el-step>
      <el-step title=""></el-step>
    </el-steps>

    <section v-if="active === 0">
      <h3 class="h3 font-semi-bold mb-5 text-center">
        Proceso de Pago
      </h3>

      <section class="plan-description-pay mt-4">
        <section class="plan-select-time line-top">
          <h4 class="h4">
            <span class="format-table">{{ getPlanCurrent.description }}</span>
            <span class="float-right"
              ><span class="font-bold"
                >$
                <TopDecimalAmount
                  formatClass="f-h4"
                  :amount="getPlanCurrent.price"/></span
            ></span>
          </h4>
          <h4 class="h4" v-if="getFirma">
            <span class="format-table">Firma electrónica</span>
            <span class="float-right"
              ><span class="font-bold"
                >$
                <TopDecimalAmount
                  formatClass="f-h4"
                  :amount="getPlanCurrent.firma"/></span
            ></span>
          </h4>
        </section>
        <section class="plan-select-subtotal line-top">
          <p>
            <span class="format-table">Subtotal</span>
            <span class="float-right"
              >$
              <TopDecimalAmount
                formatClass="f-p"
                :amount="
                  getFirma
                    ? getPlanCurrent.subTotalWithFirma
                    : getPlanCurrent.subTotal
                "
            /></span>
          </p>
          <p>
            <span class="format-table">IVA</span>
            <span class="float-right"
              >$
              <TopDecimalAmount
                formatClass="f-p"
                :amount="
                  getFirma ? getPlanCurrent.ivaWithFirma : getPlanCurrent.iva
                "
            /></span>
          </p>
        </section>
        <section class="plan-select-total line-top">
          <h3 class="h3 font-semi-bold">
            <span class="format-table">Total</span>
            <span class="float-right"
              >$
              <TopDecimalAmount
                formatClass="f-h3"
                :amount="
                  getFirma
                    ? getPlanCurrent.totalWithFirma
                    : getPlanCurrent.total
                "
            /></span>
          </h3>
        </section>
      </section>
    </section>

    <section v-if="active === 1">
      <h3 class="h3 font-semi-bold mb-5 text-break">
        <a href="" class="" @click.prevent="backStep"
          ><i
            class="el-icon-arrow-left font-black mr-4 text-color-black"
          ></i></a
        >Información de Facturación
      </h3>
      <el-form :model="form" :rules="rules" ref="form">
        <b-row>
          <b-col xs="12" sm="6">
            <el-form-item prop="first_name">
              <slot name="label">
                <label class="font-semi-bold m-0">Nombre</label>
              </slot>
              <el-input placeholder="" v-model="form.first_name"></el-input>
            </el-form-item>
          </b-col>
          <b-col xs="12" sm="6">
            <el-form-item prop="last_name">
              <slot name="label">
                <label class="font-semi-bold m-0">Apellido</label>
              </slot>
              <el-input placeholder="" v-model="form.last_name"></el-input>
            </el-form-item>
          </b-col>
        </b-row>
        <!--<b-row>
          <b-col xs="12">
            <el-form-item prop="business_name">
              <slot name="label">
                <label class="font-semi-bold m-0">Nombre Empresa</label>
              </slot>
              <el-input placeholder="" v-model="form.business_name"></el-input>
            </el-form-item>
          </b-col>
        </b-row>-->
        <b-row>
          <b-col xs="12">
            <el-form-item prop="customer_ced">
              <slot name="label">
                <label class="font-semi-bold m-0"
                  >Cédula propietario tarjetahabiente</label
                >
              </slot>
              <el-input placeholder="" v-model="form.customer_ced"></el-input>
            </el-form-item>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12">
            <el-form-item prop="customer_address">
              <slot name="label">
                <label class="font-semi-bold m-0">Dirección</label>
              </slot>
              <el-input
                placeholder=""
                v-model="form.customer_address"
              ></el-input>
            </el-form-item>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="6">
            <el-form-item prop="country">
              <slot name="label">
                <label class="font-semi-bold m-0">País</label>
              </slot>
              <SelectCountries
                :code="form.customer_country"
                @onChangeSelect="onChangeCountry"
              />
            </el-form-item>
          </b-col>
          <b-col xs="12" sm="6">
            <el-form-item prop="customer_phone">
              <slot name="label">
                <label class="font-semi-bold m-0">Teléfono</label>
              </slot>
              <el-input placeholder="" v-model="form.customer_phone"></el-input>
            </el-form-item>
          </b-col>
        </b-row>
      </el-form>
    </section>
    <section v-if="active === 2">
      <h3 class="h3 font-semi-bold mb-5 text-break">
        <a href="" class="" @click.prevent="backStep"
          ><i
            class="el-icon-arrow-left font-black mr-4 text-color-black"
          ></i></a
        >Completar Pago
      </h3>
    </section>
    <div class="text-center" v-if="getPlanCurrent.automaticDebit">
      <p><b>Activar débito automático mensual</b></p>
      <el-switch
        v-model="automaticDebit"
        active-color="#3fdbbf"
        inactive-color="#989aae"
      >
      </el-switch>
      <p class="mt-3 mb-3">
        Al activar esta opción acepta los
        <router-link :to="{ name: 'plans.terms' }" target="_blank">
          <label class="font-bold" style="color: #3fdbbf;cursor: pointer;"
            >términos de cobro recurrente</label
          >
        </router-link>
      </p>
    </div>
    <button
      v-if="active !== 2"
      type="submit"
      class="btn md__btn-primary px-5 my-4 center"
      name=""
      @click.prevent="nextStep"
    >
      Siguiente <i class="el-icon-arrow-right"></i>
    </button>
  </section>
</template>

<script>
import TopDecimalAmount from "@/components/General/TopDecimalAmount";
import Validate from "@/assets/validate";
import SelectCountries from "@/components/General/SelectCountries";
import payService from "../services/payService";

export default {
  name: "PlanPayProcessComponent",
  data() {
    return {
      active: 0,
      automaticDebit: true,
      firma_electronica: true,
      form: {
        user_business_id: null,
        amount: null,
        pay_details: "",
        first_name: "",
        last_name: "",
        business_name: "",
        customer_country: "EC",
        customer_ced: "",
        customer_address: "",
        customer_phone: "",
        customer_email: ""
      },
      rules: {
        first_name: [Validate.rules.required()],
        last_name: [Validate.rules.required()],
        // business_name: [Validate.rules.required()],
        customer_ced: [Validate.rules.required()],
        customer_address: [Validate.rules.required()],
        customer_phone: [Validate.rules.required()],
        customer_country: [Validate.rules.required()]
      }
    };
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },

    getPlanCurrent() {
      return this.$store.getters.getPlanCurrent;
    },
    getPlanTypeSelect() {
      return this.$store.getters.getPlanTypeSelect;
    },
    getFirma() {
      return this.$store.getters.getFirma;
    }
  },
  methods: {
    goPage(name) {
      this.$router.push({ name });
    },
    handleBillingForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          const pay = {
            ...this.form,
            user_business_id: this.getUser.user_business.id,
            pay_description: this.getPlanCurrent.description,
            plan_slug: this.getPlanCurrent.slug,
            amount: this.getPlanCurrent.subTotal,
            firma_electronica: this.getFirma,
            automaticDebit:
              this.getPlanCurrent.automaticDebit && this.automaticDebit
          };
          try {
            let response = await payService
              .payPpSubscription(pay)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, data: checkOutPayphone } = response.data;
            if (success) {
              if (checkOutPayphone) {
                location.href = checkOutPayphone;
              } else {
                this.$notifications.error({});
              }
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    nextStep() {
      switch (this.active) {
        case 1:
          this.handleBillingForm();
          break;
        case 2:
          break;
        default:
          this.active++;
          break;
      }
    },
    backStep() {
      this.active !== 0 && this.active--;
    },
    onChangeCountry(code) {
      this.form.customer_country = code;
    }
  },
  components: {
    TopDecimalAmount,
    SelectCountries
  },
  mounted() {},
  created() {
    const {
      name: first_name,
      last_name,
      phone: customer_phone,
      email: customer_email
    } = this.getUser;

    this.form = {
      ...this.form,
      first_name,
      last_name,
      customer_phone,
      customer_email
    };
  }
};
</script>

<style lang="scss" scoped>
.plan-pay-process {
  height: 100%;
}
.el-steps {
  display: none;
}
.center {
  margin: auto;
  display: block;
}
.borders {
  border-top: solid 1px #e3e3e3;
  border-bottom: solid 1px #e3e3e3;
}
.plan-select-time,
.plan-select-subtotal,
.plan-select-total {
  padding: 1rem 0;
}

.success-payment {
  flex-direction: column !important;
}
</style>
