<template>
  <div>
    <!-- <div v-if="planKey === getUserPlan.key">
      <button class="btn md__btn-primary mt-2 font-semi-bold">
        Plan Actual
      </button>
      <a href="#" @click.prevent="handleCancelPlan()" class="text-danger"
        >Anular plan</a
      >
    </div> -->

    <button class="btn md__btn-primary center mt-2" @click="handlePlanSelect">
      {{ title }}
    </button>
  </div>
</template>

<script>
// import payService from "../services/payService";

export default {
  name: "PlanCardButtonComponent",
  data() {
    return {
      getPlanKey: this.planKey
    };
  },
  watch: {
    planKey(v) {
      this.getPlanKey = v;
    }
  },
  methods: {
    setPlanSlug() {
      this.$store.dispatch("setPlanSlug", this.getPlanKey);
    },
    setFirma() {
      this.$store.dispatch("setFirma", this.firmaElectronica);
    },
    handlePlanSelect() {
      this.setPlanSlug();
      this.setFirma();
      this.$emit("onPlanSelect");
    }
    // async handleCancelPlan() {
    //   this.$store.dispatch("toggleRequestLoading");

    //   try {
    //     let response = await payService
    //       .membershipCancel(this.getUser.id)
    //       .finally(() => {
    //         this.$store.dispatch("toggleRequestLoading");
    //       });
    //     if (response.data.success) {
    //       this.$router.push({ name: "dashboard" });
    //     }
    //   } catch (error) {
    //     return false;
    //   }
    // }
  },
  computed: {
    // getUserPlan() {
    //   const { plan } = this.$store.getters.getUser;
    //   return plan && plan[0];
    // },
    // getUser() {
    //   return this.$store.getters.getUser;
    // }
  },
  props: {
    planKey: {
      default: null
    },
    title: {
      default: "Empezar ahora"
    },
    firmaElectronica: {
      default: false
    }
  }
};
</script>

<style></style>
