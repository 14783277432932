<template>
  <section class="" v-if="getPlanCurrent">
    <div class="md__border border-radius bg-color-write p-3 m-2 box-shadow">
      <div class="d-flex justify-content-between">
        <div>
          <h4 class="h4 font-semi-bold m-0">
            Firma Electrónica <MoreInfoComponent class="pl-2" />
          </h4>
          <p>Pago anual</p>
        </div>

        <div>
          <h2 class="h2 font-black text-color-primary d-inline-block">
            $ <TopDecimalAmount :amount="amount" />
          </h2>
          <PlanCardButtonComponent
            @onPlanSelect="handlePlanSelect"
            :planKey="planSlug"
            title="Pagar"
            v-if="showButton"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PlanCardButtonComponent from "./PlanCardButton";
import TopDecimalAmount from "@/components/General/TopDecimalAmount";
import MoreInfoComponent from "../../components/MoreInfo";

export default {
  name: "SignaturePlan",
  data() {
    return {
      amount: 35
    };
  },
  methods: {
    handlePlanSelect() {
      this.$emit("planSelected");
    }
  },
  computed: {
    getPlanCurrent() {
      return this.$store.getters.getPlans.find(p => p.slug === this.planSlug);
    },
    planSlug() {
      return `signature`;
    }
  },
  components: {
    PlanCardButtonComponent,
    TopDecimalAmount,
    MoreInfoComponent
  },
  props: {
    showButton: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped></style>
