<template>
  <el-select
    filterable
    v-model="country"
    placeholder="Seleccione país"
    class=""
    @change="onChangeSelect"
  >
    <el-option
      v-for="country in countries"
      :key="country.code"
      :label="`${country.name}`"
      :value="country.code"
    ></el-option>
  </el-select>
</template>

<script>
import { countries } from "@/services/Countries";

export default {
  name: "SelectCountries",
  data() {
    return {
      countries,
      country: this.code
    };
  },
  wacth: {
    code(v) {
      this.country = v;
    }
  },
  methods: {
    onChangeSelect() {
      this.$emit("onChangeSelect", this.country);
    }
  },
  props: {
    code: {
      default: "EC"
    }
  }
};
</script>

<style lang="scss" scoped></style>
